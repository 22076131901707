// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular.json`.

import { Environment } from '@shared/tokens/environment';
import { environmentTemplate } from './develop/environment-template.develop';

export const environment: Environment = {
	...environmentTemplate,
	pspdfkit: {
		license_key:
			'jy7AyDUOBzc3PWq7XrBGBKdV3PkZN0TW-EMAWuOvk9WY8tQHzowul-gn8JGcS9m_exkEjCzXXxQ4rhdyns81CNdUo0CnbuvYa6MAP_GK8WopZlmyDTLgXJGpQryfU8EUc8kksP0UZVdMi4_45t975i3hpDfO4cIQts0bLIx_UhR_ZMY3d54UygUdTl6_nhX4N65_iAYFvma_db_yfpoSLBF9kuTT6vQwmqgzcb23WLwmf65cBczreDYwWM7oN1Ow7ixlrxwUr1LY9HKvMx5HMNjQwbEKPJoH6m5br2jKIafHsjMa7xRPaOivzGXQL9owcmc5HoChBUzIwFb7sHe1SJwX9ByFM15Rjy4ZVBdYA9bAXCw2iXXr6ocX-8twEh5WPU0qcifnHMuvnqBvdxfB5wLuRMiat-5b_tA9wVHP1PlHMvBnQoIELut6J0j812epFaHtY-S9hzl6DWsWhuuiF5EqLDIwigrVD1GryH7sp-W2-299l02htLwNtQFlBe670bPiTroW_v4skKb7HLyNLfiAnbYgn21VmStGWYWhNrblbmcTUYx-MWK9jqZpKfWZx-PMYp-qZhpuPvWTxs507A==',
	},
	frontendComponents: {
		scripts: {
			pricingTable:
				'https://web.cdn.craftnote.cloud/pricing-table/live/pricing-table/pricing-table.esm.js',
			subscriptionCheckout:
				'https://web.cdn.craftnote.cloud/subscription-checkout/live/subscription-checkout/subscription-checkout.esm.js',
			licenseAllocation:
				'https://web.cdn.craftnote.cloud/license-allocation/live/license-allocation/license-allocation.esm.js',
			licenseManagement:
				'https://web.cdn.craftnote.cloud/license-management/live/license-management/license-management.esm.js',
		},
	},
};
